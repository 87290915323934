var site = site || {};

site.userInfoCookie = site.userInfoCookie || {};

(function ($, Drupal, Unison) {
  'use strict';

  Drupal.behaviors.siteBannerV1 = {
    attach: function (context, settings) {
      // disable for checkout
      if ($('body').hasClass('checkout')) {
        return;
      }

      $(window).on('resize', function () {
        $(window).trigger('loadSitewideBanner');
      });
      $(window).on('loadSitewideBanner', function () {
        var privateBrowsing;
        var siteHeaderContainer = 0;
        var $body = $('body');
        var is_ipad = false;
        var $siteheaderHeight = $('.js-site-header').outerHeight(true);
        var $siteHeaderClass = $('.js-site-header');
        var $subMenu = $('.submenu--sticky');

        $siteHeaderClass
          .find('.js-content-block-sitewide-banner__content-wrapper')
          .css('margin-top', $siteheaderHeight);
        Unison.on('change', function (bp) {
          setTimeout(function () {
            var bpWidth = parseInt(bp.width, 10);
            var usnxLarge = parseInt(Unison.fetch.all()['usn-x-Large'], 10);

            is_ipad = bpWidth < usnxLarge;
          }, 200);
        });
        try {
          sessionStorage.setItem('sessionTestVar', 1);
          sessionStorage.removeItem('sessionTestVar');
        } catch (err) {
          privateBrowsing = true;
        }

        if (!privateBrowsing) {
          if (!sessionStorage.getItem('originalHeaderWrapperHeight')) {
            sessionStorage.setItem('originalHeaderWrapperHeight', $('.site-header').height());
          }
          var stickyTopArray = [];
          var stickyFixElements = $(
            '.site-banner__sticky-fix, body.page-products .sec-nav, .ambient-header--raised .multi-use-tout'
          );

          if ((is_ipad && $(window).width()) > ($(window).height() && $('.page-product').length)) {
            $('.sec-nav.site-banner__sticky-fix').show();
          }
          stickyFixElements.each(function () {
            var isElementVisible = $(this).is(':visible');
            // don't do ff hack if element is already hidden.
            if (isElementVisible) {
              $(this).hide();
            }
            // firefox hack. firefox shows visible elements with top: auto as top: 0px.
            var elementCssTop = $(this).css('top');

            if ($.isNumeric(parseInt(elementCssTop))) {
              stickyTopArray.push({
                htmlElement: this,
                topPosition: parseInt(elementCssTop)
              });

              if ($('.site-banner-v1').is(':visible')) {
                $subMenu.css({ top: $siteheaderHeight + 'px' });
              }
            }
            if (isElementVisible) {
              if (is_ipad && $(window).width() < $(window).height() && $('.page-product').length) {
                $(this).hide();
              } else {
                // don't do ff hack if element is already hidden
                $(this).show();
                // end firefox hack;
              }
            }
          });

          var originalHeaderWrapperHeight = $('body.viewcart').length
            ? parseInt(sessionStorage.getItem('originalHeaderWrapperHeight'))
            : $('.site-header').height();
          var originalMobileHeaderTop;
          var siteBannerHeight;
          var siteBanner;
          var is_mac_pro_member = $.cookie('MACUSERTYPE') == 'PRO' ? 1 : 0;

          siteHeaderContainer = $('.site-header__container').height();
          if (is_mac_pro_member) {
            siteBanner = $('.site-banner__pro-user', context);
            siteBannerHeight = $('.site-banner__pro-user', context).outerHeight(true);
          } else if (parseInt(site.userInfoCookie.getValue('is_loyalty_member'))) {
            var loyaltyLevel = site.userInfoCookie.getValue('loyalty_level');

            siteBanner = $('.site-banner__loyalty-tier-' + loyaltyLevel, context);
            siteBannerHeight = $('.site-banner__loyalty-tier-' + loyaltyLevel, context).outerHeight(
              true
            );
          } else {
            siteBanner = $('.site-banner__non-pro-user', context);
            siteBannerHeight = $('.site-banner__non-pro-user', context).outerHeight(true);
          }

          siteBanner.removeClass('hidden');

          setTimeout(function () {
            var subNavHeight = 0;
            var headerCorrection = 0;

            if (
              !sessionStorage.getItem('banner_viewed') &&
              siteBanner.hasClass('site-banner__enabled') &&
              !$('html').hasClass('site-banner__is-open')
            ) {
              $(window).trigger('sidewideBanner:down');

              $('.site-header').animate({
                height: originalHeaderWrapperHeight + siteBannerHeight + 'px'
              });
              subNavHeight = $(stickyTopArray).length
                ? $(stickyTopArray[0].htmlElement).height()
                : 0;
              headerCorrection =
                $body.hasClass('has-subnav') && !site.client.isMobile
                  ? originalHeaderWrapperHeight - subNavHeight + siteBannerHeight + 'px'
                  : originalHeaderWrapperHeight + siteBannerHeight + 'px';
              $('.site-header__fixed-wrapper').animate({ height: headerCorrection }, function () {
                $(this).css('overflow', 'visible');
              });
              $(stickyTopArray).each(function () {
                $(this.htmlElement).animate({ top: this.topPosition + siteBannerHeight + 'px' });
              });
              siteBanner.slideDown(function () {
                $('.site-header__fixed-wrapper').css('overflow', 'visible');
                originalMobileHeaderTop = parseInt($('.mobile-navigation').css('top'));
                $('html').addClass('site-banner__is-open');
                $(window).trigger('sidewideBanner:down:animationComplete');
              });
            } else if (
              is_ipad &&
              $('html').hasClass('site-banner__is-open') &&
              $('nav.sec-nav').length
            ) {
              if ($(window).width() > $(window).height()) {
                $('.site-header').animate({
                  height: siteHeaderContainer + siteHeaderContainer + siteHeaderContainer + 'px'
                });
              } else {
                $('.site-header').animate({
                  height: siteHeaderContainer + siteHeaderContainer + 'px'
                });
              }
            } else if (
              is_ipad &&
              !$('html').hasClass('site-banner__is-open') &&
              $('nav.sec-nav').length
            ) {
              if ($(window).width() > $(window).height()) {
                $('.site-header').animate({
                  height: siteHeaderContainer + siteHeaderContainer + 'px'
                });
              } else {
                $('.site-header').animate({ height: siteHeaderContainer + 'px' });
              }
            } else {
              $(window).trigger('sidewideBanner:disabled');
            }
          }, 1000);
          $('.js-close-site-banner').on('keydown', function () {
            $(this).trigger('click');
          });
          $('.js-close-site-banner')
            .once()
            .on('click', function () {
              $('html').removeClass('site-banner__is-open');
              $(window).trigger('sidewideBanner:up');
              sessionStorage.setItem('banner_viewed', 1);
              siteBanner.slideUp(function () {
                $('.site-header__fixed-wrapper').css('overflow', 'visible');
                $(window).trigger('sidewideBanner:up:animationComplete');
              });
              $('.mobile-navigation').animate({ top: originalMobileHeaderTop + 'px' });
              $(stickyTopArray).each(function () {
                if ($(this.htmlElement).hasClass('site-banner__sticky-fix')) {
                  $subMenu.css({ top: $siteheaderHeight - 6 + 'px' });
                } else {
                  $(this.htmlElement).animate({ top: this.topPosition + 'px' });
                }
              });
              originalMobileHeaderTop = null;
              if ($('.has-subnav').length && !site.client.isMobile) {
                $('.site-header').animate({ height: originalHeaderWrapperHeight + 'px' });
              } else {
                $('.site-header').animate({ height: originalHeaderWrapperHeight + 'px' });
              }
              var headerReturn =
                $body.hasClass('has-subnav').length && !site.client.isMobile
                  ? originalHeaderWrapperHeight - siteBanner.outerHeight(true)
                  : originalHeaderWrapperHeight;

              $('.site-header__fixed-wrapper').animate({ height: headerReturn + 'px' });
              if (is_ipad && !$('html').hasClass('site-banner__is-open')) {
                if ($('.has-subnav').length) {
                  if ($(window).width() > $(window).height()) {
                    $('.site-header').animate({
                      height: siteHeaderContainer + siteHeaderContainer + 'px'
                    });
                  } else {
                    $('.site-header').animate({ height: siteHeaderContainer + 'px' });
                  }
                } else {
                  $('.site-header').animate({ height: siteHeaderContainer + 'px' });
                }
              }
            });
        }
      });
    }
  };
})(jQuery, Drupal, Unison);
